
import { defineComponent } from 'vue';
import apiService from "@/api/api";

export default defineComponent({
  name: "Policy",
  // components: {Header,Footer},
  data() {
    return {
      content: ''
    }
  },
  beforeMount() {
    apiService.getPage('policy').then(resp => {
      console.log('Policy RESP: ', resp);
      this.content = resp.data.page;
    });
  }
})
